.ingredient-grid {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  line-height: 1.3em;
  max-width: 1080px;
  margin: 0 auto;
  h2 {
    font-weight: 600;
    font-size: 0.9em;
  }
  p {
    font-size: 0.7em;
  }
  .ingredient-item {
    max-width: 200px;
    text-align: center;
    padding: 0.5em;
  }

}
